<template>
    <div>
        <div class="GiveMoney">
            <div class="content_info" v-if="!yes && info.isMe != 1">
                <div class="goods_item" v-for="item in orderDetails.goodsList" :key="item.pId">
                    <span>商品 : {{ item.name }}</span>
                    <span>价格：{{ item.price }}&nbsp;&nbsp;&nbsp;数量：x{{ item.number }}</span>
                </div>
            </div>

            <div class="content_info" v-else-if="!yes && info.isMe == 1">
                <div v-for="item in allOrderDetails" :key="item.id">
                    <div style="text-align: start;">{{ item.companyName }} :</div>
                    <div class="goods_item" v-for="vs in item.goodsList" :key="vs.pId">
                        <span>商品 : {{ vs.name }}</span>
                        <span>价格：{{ vs.price }}&nbsp;&nbsp;&nbsp;数量：x{{ vs.number }}</span>
                    </div>
                </div>

            </div>

            <div class="whicth" v-if="!yes">
                <div>
                    <input style="cursor: pointer;" type="radio" id="weixin" name="cc" value="weixin" v-model="ddd" /><label
                        for="weixin" style="cursor: pointer;">微信支付</label>
                </div>
                <div>
                    <input style="cursor: pointer;" type="radio" id="zhifu" name="cc" value="zhifu" v-model="ddd" /><label
                        for="zhifu" style="cursor: pointer;">支付宝支付</label>
                </div>
            </div>
            <div v-if="!yes" class="yesIs" @click="yesIs">确认支付方式</div>


            <div class="page" v-if="yes">
                <div v-if="this.isMobiles()" style="text-align: center;margin: 1% 0%;">请稍后,正在为您跳转...</div>
                <div v-else-if="this.ddd == 'weixin'" style="text-align: center;margin: 1% 0%;">
                    使用<strong>微信</strong>扫描下方二维码支付,二维码有效期为5分钟.</div>

                <div :class="this.result ? 'result' : ''" v-if="imgSrc && imgSrc != '' && num">
                    <img :src="imgSrc" />
                </div>

                <div v-if="!num && !this.isMobiles() && this.ddd == 'weixin'">二维码已过期，请刷新网页重新获取二维码.</div>
                <div v-else>请稍后...</div>
                <div v-if="this.num">({{ this.num }})</div>
            </div>
            <div v-if="yes" class="yesIs" @click="yesIs">重新选择支付方式</div>
        </div>
        <footer-com></footer-com>
    </div>
</template>

<script>
export default {
    name: 'GiveMoney',
    data() {
        return {
            info: {},
            imgSrc: '',
            num: 0,
            result: false,
            timeIn: null,
            ddd: 'weixin',
            yes: false,
            orderDetails: {},//订单详情
            allOrderDetails: []
        }
    },
    created() {
        this.info = JSON.parse(this.$route.query.info)
        if (this.info.isMe && this.info.isMe == 1) {
            this.getInfos()
        } else {
            this.getInfo()
        }
    },
    methods: {
        yesIs() {
            this.yes = !this.yes
            if (this.yes) {
                this.getWeixinResult()
            } else {
                this.imgSrc = ''
                this.num = 0
                clearInterval(this.timeIn)
            }
        },

        async getWeixinResult() {
            const loading = this.$loading({
                lock: true,
                text: "正在生成支付，请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            if (this.ddd == 'weixin') {//微信支付
                let params = {
                    orderNo: this.info.summaryOrderNo,
                    type: 1,
                    scene_info: JSON.stringify({
                        h5_info: { type: "Wap", wap_url: "https://zhenonline.com", wap_name: "百川商展-首页" },
                    }),
                    body: '百川商展-闲物互联-商品支付',
                    payType: this.isMobiles() ? 2 : 1,
                }
                let res = await this.$api.giveMoney(params)

                if (res && res.code == 200 && !this.isMobiles()) {//判断是pc
                    this.imgSrc = res.data.mwebUrl
                    this.num = 300
                    this.nums()
                    this.askMoney()
                } else if (res && res.code == 200 && this.isMobiles()) {//判断是h5
                    window.location.href = res.data.mweb_url
                } else {
                    this.$message.error(`支付失败，${res.data.err_code_des}!`)
                }
            } else {
                let params = {//支付宝支付
                    orderNo: this.info.summaryOrderNo,
                    orderTitle: '百川商展-闲物互联-商品支付',
                    payType: this.isMobiles() ? 2 : 1,//(1)PC端 and (2)移动端---判断
                }
                let res = await this.$api.giveMoneyTwo(params)
                if (res && res.code == 200) {
                    document.write(res.data)
                } else {
                    this.$message.error(`调起支付失败，${res.message}`)
                }
            }
            loading.close()
        },

        async getInfo() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getInfo({
                id: this.info.id,
                orderNumber: this.info.orderNumber,
            })
            loading.close()
            if (res && res.code == 200) {
                this.orderDetails = res.data
            } else {
                this.$message.error('网络失败请返回重试')
            }
        },

        async getInfos() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getInfos({
                orderNumber: this.info.summaryOrderNo,
            })
            loading.close()
            if (res && res.code == 200) {
                this.allOrderDetails = res.data
            } else {
                this.$message.error('网络失败请返回重试')
            }
        },

        //精准判断是否移动端
        isMobiles() {//真为移动端，假为pc端
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        nums() {
            let aa = setInterval(() => {
                if (this.num <= 0) {
                    clearInterval(aa)
                    return
                }
                this.num--
            }, 1000);
        },

        askMoney() {//轮询是否扫码支付了
            let data = {
                orderNo: this.info.summaryOrderNo,
                payType: 0,
                companyid: null,
            }
            this.timeIn = setInterval(async () => {
                if (this.result) {
                    clearInterval(this.timeIn)
                    return
                }
                let res = await this.$api.askMoney(data)
                if (res && res.code == 200 && res.data.result_code == "SUCCESS" && res.data.return_code == "SUCCESS" && res.data.trade_state == "SUCCESS") {
                    this.result = true
                    this.$router.replace({ path: "MySelf", });
                }
            }, 1000);
        },
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.timeIn)
        next()
    },
}
</script>

<style lang="scss">
.GiveMoney {
    width: 80%;
    margin: auto;
    padding-bottom: 16%;
    text-align: center;
    font-size: max(14px, 1vw);

    .content_info {
        margin-top: 1%;

        .goods_item {
            border-radius: 0.8vw;
            padding: 1%;
            border: 2px solid #eee;
            margin: 1% 0%;
            display: flex;
            color: #aaa;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .whicth {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: max(100px, 10%);
        position: relative;

        &::before {
            content: '请选择你的支付方式。';
            position: absolute;
            transform: translate(5%, -200%);
            font-weight: bold;
        }

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            >input {
                accent-color: #439C4C;
                margin-right: 5%;
            }
        }

        >div:nth-child(2) {
            margin-left: 5%;
        }
    }

    .yesIs {
        display: inline-block;
        margin: 2% auto;
        padding: 1% 5%;
        background-color: #439C4C;
        color: white;
        text-align: center;
        border-radius: max(20px, 3vw);
        cursor: pointer;

        &:active {
            transform: scale(0.95);
        }
    }

    .page {
        margin-top: 1%;

        >div {
            position: relative;

            >img {
                width: max(200px, 20vw);
            }
        }

        .result {
            >img {
                opacity: 0.3;
                filter: blur(1px);
            }

            &::after {
                content: '支付完成';
                position: absolute;
                display: block;
                color: #439C4C;
                // font-size: 1vw;
                font-weight: bold;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>